import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Router } from "@components/Router";
import { useKeycloak } from "@react-keycloak/web";
import { SpinnerWrapper } from "@components/common/SpinnerWrapper";

export default function App() {
    const { initialized } = useKeycloak();
    if (!initialized) {
        return <React.Suspense fallback={<SpinnerWrapper />}> </React.Suspense>;
    }

    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
}
