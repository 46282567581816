import crypto from "crypto";
import i18n from "i18next";

import { EMAIL_VALIDATION_REGEX } from "../constants";
import { keycloak } from "../keycloak";

export function redirectToExternalResource(url: string) {
    window.open(url);
}

export function measurePasswordStrengthAsNumber(password: string): number {
    let passwordStrengthMeter: boolean =
        password.match(/[0-9]/g) !== null &&
        password.match(/[a-z]/g) !== null &&
        password.match(/[A-Z]/g) !== null &&
        password.match(/[^A-Za-z0-9]/g) !== null;

    const EMPTY_PASSWORD = 0;
    const WEAK_PASSWORD = 1;
    const FAIR_PASSWORD = 2;
    const GOOD_PASSWORD = 3;
    const STRONG_PASSWORD = 4;

    if (password.length === 0) {
        return EMPTY_PASSWORD;
    } else if (password.length === 8 && passwordStrengthMeter) {
        return FAIR_PASSWORD;
    } else if (
        password.length > 8 &&
        password.length < 12 &&
        passwordStrengthMeter
    ) {
        return GOOD_PASSWORD;
    } else if (password.length >= 12 && passwordStrengthMeter) {
        return STRONG_PASSWORD;
    } else {
        return WEAK_PASSWORD;
    }
}

export function getPasswordStrength(result: number): {
    strength: string;
    color: string;
} {
    switch (result) {
        case 1:
            return { strength: i18n.t("Weak"), color: "danger" };
        case 2:
            return { strength: i18n.t("Fair"), color: "warning" };
        case 3:
            return { strength: i18n.t("Good"), color: "success" };
        case 4:
            return { strength: i18n.t("Strong"), color: "success" };
        default:
            return { strength: i18n.t("Weak"), color: "danger" };
    }
}

export function isEmailValid(email: string) {
    return EMAIL_VALIDATION_REGEX.test(email);
}

export async function decryptWord(
    wordToDecrypt: string,
    setter: React.Dispatch<React.SetStateAction<string>>
) {
    try {
        const response = await customFetch(
            `${process.env.REACT_APP_ADMIN_ACCESS}/decryptWord`,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    authorization: `Bearer ${keycloak.token}`,
                },
                body: `wordToDecrypted=${wordToDecrypt}`,
                method: "POST",
            }
        );
        const word = await response.text();

        if (word.length > 0) {
            setter(word);
        } else {
            setter("");
        }
    } catch (error) {
        console.log(
            `Error while send decrypting word request, with message: ${
                (error as Error).message
            }`
        );
        setter("");
    }
}

export function cryptWord(wordToCrypt: string): string {
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);

    const encryptor = crypto.createCipheriv(
        // String(`${process.env.REACT_APP_Algorithm}`),
        String("AES256"),
        key,
        iv
    );
    const aesEncrypted =
        encryptor.update(wordToCrypt, "utf8", "base64") +
        encryptor.final("base64");
    return Buffer.from(aesEncrypted).toString("base64");
}

export const customFetch = async (url: string, options?: RequestInit) => {
    try {
        const response = await fetch(url, options);

        if (!response.ok && response.status === 401) {
            keycloak.logout();
        }

        return response;
    } catch (error) {
        throw error;
    }
};
