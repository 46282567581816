import React from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import NProgressWrapper from "@components/common/NProgressWrapper";

import { customFetch } from "../../../helpers";

import "./Login.css";

export default function LoginWithSSO() {
    const { keycloak } = useKeycloak();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);

    async function setCheckingCustomerAccountRequest(email: string) {
        setLoading(true);
        await customFetch(
            `${process.env.REACT_APP_ADMIN_ACCESS}/customerAccount`,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    authorization: `Bearer ${keycloak.token}`,
                },
                body: `email=${email}`,
                method: "POST",
            }
        )
            .then((result) => result.json())
            .then((response: any) => {
                if (response.data.email !== "" && !response.error) {
                    if (response.data.etataccount === "Activer") {
                        localStorage.setItem(
                            "Uid_Entitie_User",
                            response.data.uidentitie
                        );
                        localStorage.setItem(
                            "firstName",
                            response.data.firstname
                        );
                        localStorage.setItem("lastName", response.data.name);
                        localStorage.setItem(
                            "uuidprofile",
                            response.data.uuidprofile
                        );
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem(
                            "secondaryColor",
                            response.data.secondarycolor
                        );
                        localStorage.setItem(
                            "mainColor",
                            response.data.maincolor
                        );
                        setLoading(false);
                    } else {
                        localStorage.setItem("email", response.data.email);
                    }
                }

                setLoading(false);
                navigate("/");
            })
            .catch((_) => {
                setLoading(true);
                return false;
            });
    }

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (!uidEntity && keycloak.authenticated === true) {
            setCheckingCustomerAccountRequest(keycloak.tokenParsed?.email);
        } else {
            keycloak.logout();
        }
    }, [keycloak]);

    return (
        <Container fluid={true} className="p-0 m-0 overflow-hidden">
            <NProgressWrapper isLoading={loading} />
        </Container>
    );
}
