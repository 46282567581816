import React from "react";
import { Row, CardBody, Card, Container } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import { useKeycloak } from "@react-keycloak/web";

import { customFetch, decryptWord } from "../../../../helpers";

import NProgressWrapper from "@components/common/NProgressWrapper";
import PageTitle from "@components/common/PageTitle";

import ShowPasswordSvgIcon from "@components/common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/common/SvgIcons/HidePasswordSvgIcon";
import PortalLogoSvgIcon from "@components/common/SvgIcons/PortalLogoSvgIcon";
import { CustomMainColorButton } from "@components/common/CustomMainColorButton";

import "./index.css";

export default function UpdatePassword() {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [inputEmail, setInputEmail] = React.useState<string>("");
    const [inputPwd, setInputPwd] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [messagePwdError, setMessagePwdError] = React.useState<string>(
        t("This field is required")
    );
    const [isInvalidPassword, setIsInvalidPassword] =
        React.useState<boolean>(false);
    const [isPasswordNotConfirmed, setIsPasswordNotConfirmed] =
        React.useState<boolean>(false);

    const [messageError, setMessageError] = React.useState<string>(
        t("This field is required")
    );

    const [isPasswordDisplayed, setIsPasswordDisplayed] =
        React.useState<boolean>(false);

    const [, setIsFocusInInputs] = React.useState<boolean>(false);

    async function handleValidSubmit({ values }: any) {
        setIsFocusInInputs(false);
        setLoading(true);
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_ADMIN_ACCESS}/customerAccount`;
        const email = values.email;
        const passWord = values.password;
        const confirmPassword = values.confirmPassword;
        const newPassword = values.newPassword;

        const mpdNotConform = confirmPassword !== newPassword;

        if (mpdNotConform) {
            setIsFocusInInputs(false);
            setLoading(false);
            setIsPasswordNotConfirmed(true);
        } else {
            await customFetch(urlVerifyUserAccount, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    authorization: `Bearer ${keycloak.token}`,
                },
                body: `email=${email}&password=${passWord}`,
                method: "POST",
            })
                .then((result) => result.json())
                .then(async (response: any) => {
                    if (response.error) {
                        const decryptEmail = decryptWord(
                            response.data.email,
                            setInputEmail
                        );
                        const decryptPwd = decryptWord(
                            response.data.pwd,
                            setInputPwd
                        );
                        if (inputPwd === "" && inputEmail === "") {
                            setMessageError(
                                t(
                                    "Account not found. Verify your email address."
                                )
                            );
                            setInputEmail("");
                        } else {
                            if (
                                email === decryptEmail &&
                                passWord !== decryptPwd
                            ) {
                                setIsInvalidPassword(true);
                                setInputPwd("");
                            } else {
                                if (
                                    email !== decryptEmail &&
                                    passWord === decryptPwd
                                ) {
                                    setMessageError(
                                        t(
                                            "Account not found. Verify your email address."
                                        )
                                    );
                                    setInputEmail("");
                                }
                            }
                        }
                    } else {
                        if (response.data.email !== "" && !response.error) {
                            if (response.data.etataccount === "Activer") {
                                const url = `${process.env.REACT_APP_ADMIN_ACCESS}/auth/generate-password`;
                                try {
                                    mutate(
                                        url,
                                        await customFetch(url, {
                                            headers: {
                                                "Content-Type":
                                                    "application/json",
                                                authorization: `Bearer ${keycloak.token}`,
                                            },
                                            body: JSON.stringify({
                                                password: values.password,
                                                email: values.email,
                                                newPassword: values.newPassword,
                                            }),
                                            method: "PUT",
                                        })
                                            .then((response) => response.json())
                                            .then(async (data) => {
                                                if (data.error) {
                                                    throw Error("Error!");
                                                }

                                                swal({
                                                    icon: "success",
                                                    content: (
                                                        <p>
                                                            {
                                                                t(
                                                                    "Password updated successfully"
                                                                )!
                                                            }
                                                        </p>
                                                    ),
                                                    buttons: false,
                                                    timer: 2000,
                                                });

                                                localStorage.setItem(
                                                    "Uid_Entitie_User",
                                                    response.data.uidentitie
                                                );
                                                localStorage.setItem(
                                                    "firstName",
                                                    response.data.firstname
                                                );
                                                localStorage.setItem(
                                                    "lastName",
                                                    response.data.name
                                                );
                                                localStorage.setItem(
                                                    "email",
                                                    response.data.email
                                                );
                                                setLoading(false);

                                                const url = `${process.env.REACT_APP_API_V2_URL}/userInfo?userId=${response.data.uidentitie}`;

                                                await customFetch(url, {
                                                    method: "GET",
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                        authorization: `Bearer ${keycloak.token}`,
                                                    },
                                                })
                                                    .then((response) =>
                                                        response.json()
                                                    )
                                                    .then((result) => {
                                                        if (
                                                            result.data.type ===
                                                                "boutique" ||
                                                            result.data.type ===
                                                                "Boutique"
                                                        ) {
                                                            setTimeout(() => {
                                                                navigate(
                                                                    "/applications"
                                                                );
                                                            }, 2000);
                                                        } else {
                                                            setTimeout(() => {
                                                                navigate("/");
                                                            }, 2000);
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        navigate("/");
                                                    });
                                            })
                                    );
                                } catch (e: any) {
                                    toast.error(`${t("There's an error")!}`, {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    });
                                }
                            }
                        } else {
                            return swal({
                                icon: "error",
                                content: <p>{t("There's an error")!}</p>,
                                buttons: false,
                                timer: 2000,
                            });
                        }
                    }
                    setLoading(false);
                })
                .catch((err: any) => {
                    setLoading(false);
                    return false;
                });
        }
    }

    function handleCreateAccountButtonOnClickEvent() {
        navigate("registration");
    }

    React.useEffect(() => {
        if (inputPwd.length === 0 && !isInvalidPassword) {
            setMessagePwdError(t("This field is required"));
        } else {
            setMessagePwdError(t("the current password is not correct"));
            setIsInvalidPassword(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputPwd]);

    return (
        <React.Fragment>
            <PageTitle title={t("Change password")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid={true} className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />
                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden update-card__clz w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Change password")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            <div>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e: any, v: any) => {
                                        handleValidSubmit({ values: v });
                                    }}
                                >
                                    <div className="mb-3">
                                        <StyledLabel htmlFor="email">
                                            {t("Email")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="form-control__clz"
                                            onChange={(e: any) => {
                                                setInputEmail(e.target.value);
                                            }}
                                            value={inputEmail}
                                            placeholder={t("Email")}
                                            validate={{
                                                email: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "Invalid email address"
                                                    ),
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: messageError,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="password-toggle__clz">
                                        {isPasswordDisplayed ? (
                                            <HidePasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <ShowPasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="password">
                                            {t("Current password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="password"
                                            name="password"
                                            value={inputPwd}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        messagePwdError,
                                                },
                                            }}
                                            onChange={(e: any) => {
                                                // setPassWord(e.target.value);
                                                setInputPwd(e.target.value);
                                            }}
                                            placeholder={t("Current password")}
                                            className="form-control__clz"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="password">
                                            {t("Nouveau mot de passe")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="newPassword"
                                            name="newPassword"
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t(
                                                "Nouveau mot de passe"
                                            )}
                                            className="form-control__clz"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="confirmPassword">
                                            {t("Confirm the new password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={(e: any) => {
                                                setIsPasswordNotConfirmed(
                                                    false
                                                );
                                            }}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t(
                                                "Confirm the new password"
                                            )}
                                            className="form-control__clz"
                                        />

                                        {isPasswordNotConfirmed ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Sorry, your passwords not confirmed"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="d-flex justify-content-center submit-button__clz">
                                        <CustomMainColorButton
                                            variant="primary"
                                            rounded={true}
                                            outline={false}
                                            className="text-uppercase"
                                            type="submit"
                                        >
                                            {t("Connection")}
                                        </CustomMainColorButton>
                                    </div>
                                    <div className="d-none">
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                            }}
                                            onClick={
                                                handleCreateAccountButtonOnClickEvent
                                            }
                                        >
                                            {t("Create new account")}
                                        </StyledButton>
                                    </div>
                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );
}
